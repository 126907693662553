import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { Field, FieldInput } from '@dougs/fields/dto';
import {
  Task,
  TaskConfiguratorContext,
  TaskConfiguratorContextVariableAnyType,
  TaskFormInlineGroup,
} from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { TaskFormFieldsComponent } from '../../components/control-panel/task/task-form-fields/task-form-fields.component';

@Component({
  selector: 'dougs-task-configurator-modal',
  templateUrl: './task-configurator-modal.component.html',
  styleUrls: ['./task-configurator-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    TaskFormFieldsComponent,
    PanelInfoComponent,
  ],
})
export class TaskConfiguratorModalComponent implements OnInit {
  public taskFormInlineGroup!: TaskFormInlineGroup;

  private generateInputFromContextVariableType(contextVariable: TaskConfiguratorContextVariableAnyType): FieldInput {
    if (contextVariable.type === 'string') {
      if (contextVariable.possibleValues) {
        return {
          type: 'select',
          labels: {
            items: contextVariable.possibleValues.map((i) => ({
              value: i.value,
              label: i.label ?? i.value,
            })),
          },
        };
      }
      return {
        type: 'textarea',
      };
    } else if (contextVariable.type === 'number') {
      return {
        type: 'number',
        min: contextVariable.min,
        max: contextVariable.max,
      };
    } else if (contextVariable.type === 'boolean') {
      return {
        type: 'yesno',
      };
    } else if (contextVariable.type === 'Date') {
      return {
        type: 'date',
      };
    }

    throw new Error(`Unknown contextVariable type ${contextVariable}`);
  }

  private taskFormInlineGroupAsContext(): TaskConfiguratorContext {
    if (!this.taskFormInlineGroup.items) {
      return {};
    }

    return this.taskFormInlineGroup.items
      .filter((field) => field.value !== undefined)
      .reduce((prev, field) => ({ [field.id]: field.value, ...prev }), {});
  }

  constructor(
    @Inject(MODAL_DATA)
    public data: { task: Task; context: TaskConfiguratorContextVariableAnyType[] },
    private readonly modalRef: ModalRef<TaskConfiguratorContext, { data: TaskConfiguratorContextVariableAnyType[] }>,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  ngOnInit(): void {
    this.taskFormInlineGroup = {
      type: 'field',
      direction: 'row',
      enableFormChangeUpdate: true,
      items: this.data.context.map(
        (contextVariable): Field => ({
          id: contextVariable.id,
          field: 'value',
          isAvailable: true,
          isRequired: true,
          isEditable: true,
          input: {
            isRequired: contextVariable.isRequired,
            ...this.generateInputFromContextVariableType(contextVariable),
          },
          label: contextVariable.label,
          value: contextVariable.value,
        }),
      ),
    };
  }

  async submitTaskForm(): Promise<void> {
    this.modalRef.close(this.taskFormInlineGroupAsContext());
  }

  async onTaskFormChange(): Promise<void> {
    await this.controlPanelTasksStateService.updateTaskConfigurationV2(
      this.data.task,
      this.taskFormInlineGroupAsContext(),
    );
  }

  async closeTaskForm(): Promise<void> {
    this.modalRef.close();
  }
}
