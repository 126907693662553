<div dougsModalTitle>
  <h6>
    <i class="fal fa-receipt mr-4"></i>
    Configurer la tâche
  </h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <dougs-panel-info class="mb-16">
    <div class="automatic-save-message">
      <i class="fal fa-floppy-disk color-primary save-icon"></i>
      <p class="small">Toutes vos modifications sont sauvegardées automatiquement.</p>
    </div>
  </dougs-panel-info>
  <div class="configure-task">
    <dougs-task-form-fields
      (submitTaskForm)="submitTaskForm()"
      (changeTaskForm)="onTaskFormChange()"
      (closeTaskForm)="closeTaskForm()"
      [disabledCancel]="true"
      [taskFormInlineGroup]="taskFormInlineGroup"
    ></dougs-task-form-fields>
  </div>
</div>
